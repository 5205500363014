import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import './error_page.scss';

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const NotFoundError = (
  {
    data: {
      ribbon,
      header,
      footer,
    },
  }
) => {
  return (
    <Layout header={header} ribbon={ribbon} footer={footer}>
      <Seo title={"404 Page not found"} metaDescription={"404 Page not found"} />
      <section className="error_page_section">
        <div className="container">
          <h4>404 Error</h4>
          <h5>Page Not Found</h5>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundError;
